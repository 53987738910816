<template>
    <div style="padding: 30px;">
        <p>MYBOAT TERMS AND CONDITIONS</p>

        <p>Your use of MyBoat&#39;s products, software, services, website and any mobile applications (collectively
            &quot;Services&quot;) is subject to the terms of these Terms and Conditions. MyBoaT is herein referred to as
            &quot;MyBoat,&quot; &quot;us,&quot; &quot;we,&quot; or &quot;our&quot;). If you are using the Services on behalf
            of a corporation or other legal entity, &quot;you&quot; means the entity, and the user is warranting that he or
            she has the rights to bind the entity. Your access and use of the Services constitutes your agreement to be
            bound by these Terms and Conditions, which establishes a contractual relationship between you and MyBoat.
            However, if you do not agree to all of the Terms and Conditions you are not authorized to use MyBoat&#39;s
            Services. These Terms and Conditions expressly supersede prior agreements or arrangements with you.</p>

        <p>You accept the Terms and Conditions by: (i) clicking to accept or agree to the Terms and Conditions, where this
            option is made available to you by MyBoat in the user interface for any Service; or, (ii) by actually using the
            Services. In the case of (ii), you understand and agree that MyBoat treats your use of the Services as
            acceptance of the Terms and Conditions from that point onward.</p>

        <p>MyBoat reserves the right, at its discretion, to change, modify, add or remove any of the Terms and Conditions,
            in whole or in part, at any time for any reason whatsoever. Please check the Services and the Terms and
            Conditions periodically for changes. Your use of the Services after such modified Terms and Conditions are
            posted will mean that you accept such Terms and Conditions.</p>

        <p>1. DEFINITIONS</p>

        <p>In addition to terms defined elsewhere in this Agreement, the following definitions apply throughout this
            Agreement, unless the contrary intention appears:</p>

        <p>&ldquo;Boat Operator&rdquo; means the person, agency or company responsible for operating or managing the boat
            services.</p>

        <p>&ldquo;Customer&rdquo; means a visitor of the Platforms or a customer of the Boat Operator.</p>

        <p>&ldquo;MyBoat.com platform&rdquo; means the website(s), apps, tools, platforms or other devices of MyBoat.com on
            which the Service is made available.</p>

        <p>&ldquo;Service&rdquo; means the online boat reservation system of MyBoat.com through which Boat Operators can
            make their boat(s) available for reservation, and through which Customers can make reservations with such Boat
            Operators.</p>

        <p>&nbsp;</p>

        <p>&nbsp;</p>

        <p>2. USE OF THE SERVICES</p>

        <p>2.1 Conditions of Use</p>

        <p>As a condition of your use of the Services, you expressly represent and warrant that:</p>

        <p>(i) if you will be operating a boat, you are responsible for ensuring that you are sufficiency skilled in the
            operation of the boat and legally authorized to operate a boat in the state in which you are renting and you
            will not allow any other occupants of the boat to operate the boat unless they are each of sufficient skill and
            legally authorized to operate a boat in the state in which you are renting;</p>

        <p>(ii) you possess the legal authority to create a binding legal obligation;</p>

        <p>(iii) you will use the Services in accordance with the Terms and Conditions;</p>

        <p>(iv) you will only use the Services to post or view, as applicable, available boat rentals or transact legitimate
            boat rentals for you or for another person for whom you are legally authorized to act with other Users;</p>

        <p>(v) you will inform such other persons about the Terms and Conditions that apply to the boat rental you have made
            on their behalf, including all laws, regulations, rules and restrictions applicable thereto;</p>

        <p>(vi) if you are booking a rental on behalf of another person, you are individually responsible for ensuring that
            such other person is a party this Terms and Conditions;</p>

        <p>(vii) all information supplied by you to MyBoat is true, accurate, current and complete;</p>

        <p>(viii) if you have a MyBoat account, you will safeguard your account information;</p>

        <p>(ix) you are and will remain in compliance with all applicable laws, rules and regulations related to boat
            operation and rental; and</p>

        <p>(x) You may not authorize others to use your user account, and you may not assign or otherwise transfer your user
            account to any other person or entity.</p>

        <p>If you reside in a jurisdiction that restricts the use of the Services because of age, or restricts the ability
            to enter into agreements such as this one due to age, you must abide by such age limits and you must not use the
            Services. Without limiting the foregoing, the Services are not available to children (persons under the age of
            18). By using the Services, you represent and warrant that you are at least 18 years old. MyBoat retains the
            right at its sole discretion to deny access to the Services to anyone at any time and for any reason, including,
            but not limited to, for violation of the Terms and Conditions.</p>

        <p>Sharing contact information such as, email address, phone number, website address, company name, social media
            accounts or profiles, pickup locations, contact information to other members, or any other form to connect
            directly with users outside of MyBoat is strictly prohibited. MyBoat has the sole discretion to monitor and
            block all content that it deems to be used to circumvent MyBoat or violates any other terms of use. Failing to
            abide by the terms will result in an account suspension and removal from the Platform. You agree that you will
            not try to circumvent MyBoat by any means which includes but is not limited to conversations posted to
            MyBoat&#39;s messaging platform, boat listings, public and private reviews, and images uploaded.</p>

        <p>You agree that you will only use the Services for lawful purposes.</p>

        <p>Please keep in mind that we will treat anyone who uses your user name and password as &quot;you.&quot; We will
            provide this user with all of the rights and privileges that we provide to you, and we will hold you responsible
            for the activities of the person using your password. Therefore, we recommend that you maintain your user name
            and password in confidence, and that you refrain from disclosing this information to anyone who might
            &quot;pretend&quot; to be you with respect to the Services. We also ask that you notify us immediately if you
            suspect that someone is using your user name and/or password or otherwise obtaining access to the Services in
            this or any other inappropriate manner.</p>

        <p>2.2 Payment Services</p>

        <p>While MyBoat is not a party to any agreement between you and any member, renter acknowledges and agrees that
            MyBoat may act as the</p>

        <p>member&#39;s payment agent for the purposes of collecting payment for bookings made through the Services.</p>

        <p>2.3 Establishing Payment Account</p>

        <p>As a renter, you are responsible for opening an account, providing payment authorizations for bookings and paying
            for Services according to these Terms and Conditions, as well as others which may be included at the time of
            booking. You agree to provide all requested information, including a credit card to be used for
            pre-authorization/reserve and payment/capture for Services. Payment for a booking will be made by MyBoat
            directly to the member who owns the boat(s).</p>

        <p>By providing your credit card information to us, you agree that we are authorized to charge your card with the
            total cost of the rental, taxes and any required deposit, as described below. We will run a pre-authorization
            for the full amount of the booking on the credit card the renter supplies at the time of booking. You agree that
            we can charge a nominal amount to your credit card to verify the validity of the credit card information you
            provide. If the member accepts the booking, the renter&#39;s credit card will be charged in full for the amount
            previously described, although if the member advises us of a change in delivery location following the booking
            and that change results in an increase in any applicable taxes we are required to charge and collect or if there
            is increase in the amount to taxes imposed at the time of booking versus the date of delivery, you expressly
            authorize us to charge such credit card for the excess and if that card is not honored at the time MyBoat will
            collect such amount from the member and the member will collect such amount from you before delivery of the
            rental to you and your failure to pay the Member for such amount may result in denial of access to the rental
            and forfeiture of your deposit. You expressly authorize MyBoat to originate credit transfers to your financial
            institution account. MyBoat may also use a service provider to store your credit card information to facilitate
            payments as described herein and you expressly authorize such storage. MyBoat shall ensure that any service
            provider it uses to process or store your credit card information is bound by obligations to protect such
            information.</p>

        <p>&nbsp;</p>

        <p>2.4 Deposits</p>

        <p>Renters are responsible for leaving the rented boat in the same condition it was in before you rented it. Renters
            acknowledge and agree that you</p>

        <p>are responsible for all acts and omissions of you and your guests on the boat that affect the condition of the
            boat. In the event a member claims and provides evidence of damage to the boat, you agree that MyBoat shall be
            entitled to charge a deposit to your credit card to cover the cost of the damage, as established after
            reasonable investigation. We will notify you of any damage claim made by a member related to your rental and you
            will have an opportunity to respond, as set forth in such notice. We will only charge a deposit to renter&#39;s
            credit card after the rental is complete if the member has made a claim and the deposit amount has been deemed
            appropriate after investigation of the claim.</p>

        <p>2.5 Service Charge</p>

        <p>We charge a service fee of [●●●] of the total rental (i.e., the booking and any additional expenses such as taxes
            we are required to collect) for facilitating the rental and this amount will be reflected in the final amount
            charged to renter&#39;s credit card. We reserve the right to round up amounts to the nearest whole Saudi Riyals
            and/or dollar (for example, $202.60 will be rounded to $203). We will process any necessary currency conversion
            (MyBoat charges in Saudi Riyals and/or US dollars unless otherwise specified) based upon renter&#39;s currency
            on the day the credit card is charged and we will charge a conversion fee of 3% of the total rental for currency
            conversions. We reserve the right to adjust the conversion rate based upon when the payout is made to the
            member. The service fee and any conversion fee constitute &quot;Transaction Fees.&quot; MyBoat reserves the
            right to adjust its Transaction Fees at any time. Any Fee changes will be effective the next time you book a
            rental after such fee change goes into effect (i.e., rentals already booked will be subject to Fees as
            established at the time of booking).</p>

        <p>2.6 Substitution of Rental Units</p>

        <p>Renter understands and acknowledges that boats/other water craft reserved may, from time to time, be subsequently
            damaged or otherwise become in need of repair before the reservation is commenced. If a member is unable to
            provide the reserved unit to renter due to it being unavailable, member may provide renter with a substitute
            unit that is similar in age, size, model, and value as the reserved boat/other water craft. Member shall provide
            renter with notice of the need for a substitution of unit(s) as soon as reasonably possible, as well as provide
            renter with pictures and a description of the substitute unit upon request. If member is unable to provide
            renter with a similar substitute unit (as described herein), renter may elect to cancel the reservation any time
            before it is commenced and, as its sole remedy, obtain a refund in accordance with the refund policy as if the
            member had cancelled the reservation. Renter shall not be entitled to any refund, and shall be obligated to pay
            all sums owed, if member offers renter a similar unit (as described herein) and renter nevertheless refuses to
            complete and/or terminates the reservation. If there is a dispute between renter and</p>

        <p>member as to whether the substitute unit offered by member is sufficiently similar to the unit reserved by
            renter, MyBoat shall make the final determination, in its sole discretion, and renter and member agree to abide
            by MyBoat&#39;s determination.</p>

        <p>&nbsp;</p>

        <p>3. DISCLAIMERS; LIMITATION OF LIABILITY; INDEMNITY.</p>

        <p>3.1 Disclaimers</p>

        <p>The services are provided &ldquo;as is&rdquo; and &ldquo;as available&rdquo;. MyBoat disclaims all
            representations and warranties, express, implied or statutory, not expressly set out in these terms, including
            the implied warranties of merchantability, fitness for a particular purpose and non-infringement. In addition,
            MyBoat makes no representation, warranty, or guarantee regarding the reliability, timeliness, quality,
            suitability or availability of the Services or any services or goods requested through the use of the Services,
            or that the Services will be uninterrupted or error-free. MyBoat does not guarantee the quality, suitability,
            safety or ability of third-party providers. You agree that the entire risk arising out of your use of the
            Services, and any service requested in connection therewith, remains solely with you, to the maximum extent
            permitted under applicable law.</p>

        <p>3.2 LIMITATION OF LIABILITY</p>

        <p>MyBoat shall not be liable for indirect, incidental, special, exemplary, punitive or consequential damages,
            including lost profits, lost data, personal injury or property damage related to, in connection with, or
            otherwise resulting from any use of the Services, even if MyBoat has been advised of the possibility of such
            damages. MyBoat shall not be liable for any damages, liability or losses arising out of: (i) your use of or
            reliance on the Services or your inability to access or use the Services; or (ii) any transaction or
            relationship between you and any boat owner, even if MyBoat has been advised of the possibility of such damages.
            MyBoat shall not be liable for delay or failure in performance resulting from causes beyond MyBoat&rsquo;s
            reasonable control. In no event shall MyBoat&rsquo;s total liability to you in connection with the services for
            all damages, losses and causes of action exceed (SR 500) five hundred Saudi Riyals. The limitations and
            disclaimer in this Clause do not purport to limit liability or alter your rights as a User that cannot be
            excluded under applicable law.</p>

        <p>&nbsp;</p>

        <p>3.3 INDEMNITY</p>

        <p>You agree to indemnify and hold MyBoat and its officers, directors, employees and agents harmless from any and
            all claims, demands, losses, liabilities, and expenses (including attorneys&rsquo; fees) arising out of or in
            connection with:</p>

        <p>(i) your use of the Services;</p>

        <p>(ii) your breach or violation of any of these Terms and Conditions; and</p>

        <p>(iii) your violation of the rights of any third party, including boat(s)&rsquo; owners..</p>

        <p>&nbsp;</p>

        <p>4. GENERAL TERMS AND CONDITIONS</p>

        <p>4.1 General Compliance with Laws</p>

        <p>You shall comply with all applicable laws, statutes, ordinances and regulations regarding your use of the
            Services.</p>

        <p>4.2 Headings</p>

        <p>Headings are for reference purposes only and in no way define, limit, construe or describe the scope or extent of
            such section.</p>

        <p>4.3 Assignment</p>

        <p>The Terms and Conditions, the Services and any modifications thereto may not be assigned or in any way
            transferred by you without the prior written consent of MyBoat. However, MyBoat may assign this Terms and
            Conditions as well as any information which relates to MyBoat&rsquo;s business provides including, but not
            limited to, any informations in connection with a sale of its business, assets, a merger or other similar
            transaction.</p>

        <p>4.4 Governing Law and Dispute Resolution</p>

        <p>This Terms and Condition shall be governed by and construed in all respects in accordance with the laws of the
            Kingdom of Saudi Arabia. The original form of this Agreement has been written in English.</p>

        <p>Any dispute or claim arising out of or in connection with this Agreement shall be finally settled by arbitration
            in accordance with the Arbitration Regulations of the Kingdom of Saudi Arabia and its Implementing Rules which
            are applicable at the time of reference to the arbitration. The language to be used in any arbitral proceedings
            shall be Arabic. The arbitration proceedings shall be held in Jeddah, Kingdom of Saudi Arabia.</p>

        <p>4.5 No Responsibility for Force Majeure Events</p>

        <p>MyBoat shall not be liable for any delay or failure to perform in connection with any booking, Service or in the
            performance of any obligation hereunder, if such delay or failure is due to or in any manner caused by force
            majeure events including but not limited to acts of God, rebellion, riots, hijacking, insurrection, civil
            commotion, strikes or labor disputes, fires, floods, laws, regulations, acts, demands or and orders of any
            government or agency, seizure of the boat under legal process, adverse weather conditions, inability to obtain
        </p>

        <p>fuel, boat damage or loss, lack of essential parts or supplies, mechanical problems, illness or incapacitation of
            crew members, denial of operating or docking approvals, clearances or permits by governmental authority, or any
            other cause which is beyond the control of My Boat.</p>

        <p>MyBoat is hereby released by a User from any claim or demand for any direct or consequential damages arising out
            of failure to perform as a result of a force majeure event whether or not herein enumerated or other event
            referenced above.</p>

        <p>4.6 Severability</p>

        <p>If any provision of this Terms and Conditions is deemed to be or becomes incomplete or invalid, or must be so,
            the invalidity or ineffectiveness of such provision shall not affect the validity and effectiveness of the
            remaining provisions hereof. In such event, MyBoat and the User shall re-negotiate in good faith a new suitable
            provision that would lead to the fulfillment of the purpose of the invalid provision. The new provision so
            agreed by MyBoat and the User must provide the same rights and obligations to, and shall substantially have the
            same effect on, both parties.</p>

        <p>4.7 Waiver</p>

        <p>No waiver of any of the Terms shall be deemed a waiver of any other such Terms and Conditions set forth herein.
        </p>

        <p>4.8 Entire Agreement</p>

        <p>The Terms and Conditions contains the entire agreement between the MyBoat and the User with respect to the
            subject matter hereof, and supersedes all previous oral and/or written agreements and understandings between
            MyBoat and the User the with respect thereto, and may not be amended or modified except by an instrument in
            writing signed by the duly authorized representatives of the parties.</p>

        <p>&nbsp;</p>

        <p>4.9 Termination</p>

        <p>MyBoat may immediately issue a warning, temporarily suspend, indefinitely suspend or terminate User&#39;s use of
            all or any portion of the Services if you breach the Terms and Conditions, or at any time or for any reason, at
            the sole discretion of MyBoat.</p>

        <p>4.10 Non-Endorsement</p>

        <p>MyBoat does not recommend one member over another and does not provide advice to users regarding which member to
            select for the rentals. All comments or reviews posted by MyBoat users, renters, or members on our Services do
            not represent the views of MyBoat in any way and are not endorsed by MyBoat absent a specific written statement
            to the contrary.</p>

        <p>4.11 No Relationship</p>

        <p>Nothing contained or implied in this Terms and Conditions shall constitute or be deemed to constitute a
            partnership between the MyBoat and the User (or any of them) and none of the parties shall have any authority to
            bind or commit any other party in any way, save as expressly set out herein.</p>

        <p>&nbsp;</p>

        <p>4.12 Notice of Claim</p>

        <p>You agree that all disputes arising out of the Terms and Conditions or the use of MyBoat&#39;s Services shall be
            communicated to MyBoat in writing within (15) fifteen days from the date of the incident causally related to the
            dispute. You hereby waive your right to any claim against MyBoat not communicated as provided by this Clause.
        </p>

        <p>5.13 Contacting Us</p>

        <p>If you have any questions, concerns or comments about this Terms and Conditions or our Services, please contact
            us via the following e-mail address and/or number:</p>

        <p>[MyBoat]</p>

        <p>E-mail: [●●●]</p>

        <p>number: [●●●]</p>

        <p>&nbsp;</p>

        <p>Any rights not expressly granted herein are reserved by MyBoat.</p>

        <p>&nbsp;</p>

        <p>&nbsp;</p>

        <p>&nbsp;</p>

        <p>&nbsp;</p>

        <p>&nbsp;</p>

        <p>5. TERMS AND CONDITONS RELATING TO VAT</p>

        <p>5.1 General</p>

        <p>1These specific terms and conditions relating to VAT (the VAT Terms) shall apply from the date of their issuance
            and shall continue to apply unless and until expressly terminated by MyBoat.</p>

        <p>2. Your continued use of the MyBoat Platform on or after the date of your receipt of the VAT Terms shall mean
            your acceptance of the VAT Terms.</p>

        <p>3. Defined terms shall have the meaning provided for in the Terms and Conditions above, unless expressly defined
            differently in the VAT Terms.</p>

        <p>5.2 Application of VAT on Renting Boat(s).</p>

        <p>Further to instructions received from the Zakat, Tax and Customs Authority (&ldquo;ZATCA&rdquo;). VAT is now
            chargeable on the total fare of renting boat(s) taken in the Kingdom of Saudi Arabia at the prevailing rate. In
            the event that these instructions change and such change applies retrospectively, MyBoat shall seek to refund
            such amounts of VAT that may be subsequently refunded to MyBoat by the ZATCA, in accordance with the terms of
            the VAT Refund section below.</p>

        <p>&nbsp;</p>

        <p>5.3 VAT Refund</p>

        <p>&nbsp;</p>

        <p>1. MyBoat makes no guarantee or assurance that the VAT charged on the total trip fare will be recovered from the
            ZATCA or refunded to you.</p>

        <p>2. MyBoat shall be entitled to deduct amounts to reflect administrative expenses and such other costs (including
            penalties and any assessment of unpaid VAT) necessarily incurred as a result of implementing ZATCA&rsquo;s
            instructions, prior to making a refund of VAT to you.</p>

        <p>3. Any amount of VAT to be refunded to you shall be credited to your Account in the form of credits that are
            non-transferable, non redeemable against cash, available for use in Saudi Arabia only and with an expiry date of
            one year from their issuance (unless otherwise made available by MyBoat). You agree and accept that you shall
            have no entitlement to receive the VAT refund as cash.</p>

        <p>4. In the event that you have closed your Account as at the time the VAT refund takes place, MyBoat will send you
            via email a voucher (equal to the amount of any refund that you are owed) that is redeemable on the MyBoat
            Platform. The voucher will be personal to you and you may redeem it by opening a new personal account on the
            MyBoat Platform or reactivating your closed account. If MyBoat does not have a record of your email (whether
            because it was not provided to MyBoat initially or because MyBoat deleted such information based on your request
            or due to any other reason), MyBoat will not seek to contact you and any refund will be subject to your request
            made in accordance with paragraph (5) below. MyBoat shall not be liable for any voucher sent to the email
            address it has in its records if such voucher is received and/or redeemed by any person other than you.</p>

        <p>5. In the event that you have closed your Account as at the time the VAT refund takes place and that you were not
            contacted by MyBoat, you shall be entitled to contact MyBoat to reclaim VAT. Refund will be via vouchers
            redeemable on the MyBoat Platform. MyBoat will have th</p>

        <p>(including trip receipts you have received by email from MyBoat) to authenticate your claim.</p>

    <p>6. You agree and accept that you shall not seek to claim VAT refunds from MyBoat after a period of (12) twelve
        months from the date that you became aware of a potential entitlement to reclaim VAT, and in any event no more
        than (5) five years from the date that such entitlement arose.</p>

</div></template>

<script>
// eslint-disable-next-line object-curly-newline


export default {

    setup()
    {
        return {
            
        }
    },
}
</script>
